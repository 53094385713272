import React from "react";
import styled from "styled-components";
import { brandColor2 } from "../styles/Colors";
import { mediaMedium } from "../styles/variables";
import Image from "../Image";
import { graphql, useStaticQuery } from "gatsby";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Wrapper = styled.div`
  background-color: ${brandColor2};
  overflow: hidden;
`;

const Div = styled.div`
  padding-top: 40px;
  overflow: hidden;
  max-width: 100%;

  .gatsby-image-wrapper {
    max-width: 150% !important;
    max-height: none !important;
    width: 150%;
  }

  .slick-slide {
    border: none;
    outline: none;
  }

  @media (${mediaMedium}) {
    padding: 88px 0 64px;
  }
`;

const EntrepreneurSliderStudioSection = ({ data }) => {
  const settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 400,
    slidesToShow: 4,
    draggable: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };
  return (
    <Wrapper>
      <Div>
        <Slider {...settings}>
          {data.allFile.edges.map((item, key) => {
            return (
              <div key={`slider-${key}`}>
                <Image fluid={item.node.childImageSharp.fluid} />
              </div>
            );
          })}
        </Slider>
      </Div>
    </Wrapper>
  );
};

const EntrepreneurSliderStudio = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: { name: { glob: "*" }, dir: { regex: "/images/entrepreneur/" } }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 587, maxHeight: 791) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
    }
  `);
  return <EntrepreneurSliderStudioSection data={data} />;
};

export default EntrepreneurSliderStudio;
