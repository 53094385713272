import React from "react";
import styled from "styled-components";
import { mediaMedium } from "../styles/variables";
import { Headline96 } from "../styles/Headings";
import { Eyebrows20 } from "../styles/Eyebrows";
import Container from "../styles/Container";
import Image from "../Image";
import { graphql, useStaticQuery } from "gatsby";

const Grid = styled.div`
  position: relative;

  @media (${mediaMedium}) {
    display: grid;
    grid-template-columns: calc(100% * 8 / 12) calc(100% * 4 / 12);
    grid-column-gap: 23px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  max-width: 100vw;
  background-color: transparent;
  padding-top: 42px;

  @media (${mediaMedium}) {
    padding-top: 78px;
    padding-bottom: 140px;
  }
`;

const Title = styled(Headline96)`
  margin-top: 22px;

  @media (${mediaMedium}) {
    margin-top: 30px;
  }
`;

const Display = styled.div`
  margin-bottom: -184px;
  margin-top: 30px;
  position: relative;
  z-index: 10;

  @media (max-width: 414px) {
    .gatsby-image-wrapper {
      max-width: 100% !important;
      max-height: none;
    }
  }

  @media (${mediaMedium}) {
    transform: translateX(10%) translateY(8%) scale(1.5);
    position: relative;
  }
`;

const DisplayWrapper = styled.div`
  text-align: center;

  .gatsby-image-wrapper {
    margin: 0 auto;
  }

  @media (max-width: 414px) {
    .gatsby-image-wrapper {
      max-width: 100% !important;
      max-height: none;
    }
  }

  @media (${mediaMedium}) {
    position: absolute;
    top: -30px;
    width: 100%;
  }
`;

const HomeLandingSection = ({ children, section, data }) => {
  return (
    <Wrapper>
      <Container>
        <Grid>
          <div>
            <Eyebrows20>{section}</Eyebrows20>
            <Title>{children}</Title>
          </div>

          <Display>
            <DisplayWrapper>
              <Image fluid={data.file.childImageSharp.fluid} />
            </DisplayWrapper>
          </Display>
        </Grid>
      </Container>
    </Wrapper>
  );
};

const HomeLanding = (props) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "34-entrepreneur-landing.png" }) {
        childImageSharp {
          fluid(maxWidth: 509, maxHeight: 721, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <HomeLandingSection data={data} {...props} />;
};

export default HomeLanding;
