import React from "react";
import styled from "styled-components";
import { gray4 } from "../styles/Colors";
import { mediaMedium } from "../styles/variables";
import Image from "../Image";
import { graphql, useStaticQuery } from "gatsby";
import Container from "../styles/Container";

const Wrapper = styled.div`
  background-color: ${gray4};
  overflow: hidden;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 22px;
  height: 788px;
  grid-template-columns: repeat(2, 1fr);

  @media (${mediaMedium}) {
    align-items: flex-end;
    height: 1055px;
    padding-top: 104px;
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Item = styled.div`
  .gatsby-image-wrapper {
    max-width: 100% !important;
  }

  &:nth-child(2) {
    transform: translateY(48px);
  }

  &:nth-child(1),
  &:nth-child(3) {
    transform: translateY(-50%);
  }

  @media (${mediaMedium}) {
    transform: none !important;
  }
`;

const ShowCaseSection = ({ data }) => {
  return (
    <Wrapper>
      <Container>
        <Grid>
          {data &&
            Object.keys(data).map((item, key) => (
              <Item key={item} order={key + 1}>
                <Image fluid={data[item].childImageSharp.fluid} />
              </Item>
            ))}
        </Grid>
      </Container>
    </Wrapper>
  );
};

const ShowCaseEntrepreneur = () => {
  const data = useStaticQuery(graphql`
    query {
      one: file(relativePath: { eq: "36-entrepreneur-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 340, maxHeight: 959, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      two: file(relativePath: { eq: "36-entrepreneur-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 340, maxHeight: 959, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      three: file(relativePath: { eq: "36-entrepreneur-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 340, maxHeight: 959, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <ShowCaseSection data={data} />;
};

export default ShowCaseEntrepreneur;
