import React, { createRef, PureComponent } from "react";
import { Headline100 } from "./styles/Headings";
import { gray1, white } from "./styles/Colors";
import { CountUp } from "countup.js";
import { Body20 } from "./styles/BodyTypes";
import styled from "styled-components";
import { fontProxima, mediaMedium, mediaExtraSmall } from "./styles/variables";

const Div = styled.div`
  width: ${(props) => props.theme.width ?? "425px"};
  max-width: 100%;
  margin: 16px 0 0;
  text-align: ${(props) => (props.align ? "center" : "")};
  transition: ${(props) => (props.active ? "1.9s" : "0s")} ease-in color;
  color: ${(props) => (props.active ? white : gray1)};
`;

const Numbers = styled(Headline100)`
  font-weight: 900;
  margin: 0;
  display: block;
  font-family: ${fontProxima};
  text-transform: uppercase;
  font-size: 56px;
  letter-spacing: -1.5px;
  line-height: 52px;
  transition: ${(props) => (props.active ? "1.9s" : "0s")} ease-in color;
  color: ${(props) => (props.active ? white : gray1)};

  @media (${mediaMedium}) {
    font-size: 112px;
    letter-spacing: -3px;
    line-height: 104px;
  }

  @media (${mediaExtraSmall}) {
    font-size: 44px;
    letter-spacing: -1.2px;
  }
`;

const easingFn = function (t, b, c, d) {
  let ts = (t /= d) * t;
  let tc = ts * t;
  return b + c * (tc + -3 * ts + 3 * t);
};

class MetricSnippetAlt extends PureComponent {
  constructor(props) {
    super(props);

    this.observerRef = createRef();

    this.state = {
      observer: null,
      play: false
    };
  }

  componentDidMount() {
    this.initPositionObserver();
  }

  componentWillUnmount() {
    if (this.state.observer) {
      this.state.observer.disconnect();
    }
  }

  initPositionObserver() {
    const self = this;
    const intersectionCallback = (entries) => {
      for (let i = 0; i < entries.length; i++) {
        if (entries[i].isIntersecting && entries[i].intersectionRatio > 0.5) {
          if (!self.state.play) {
            self.startAnimation();
          }
        } else {
          self.setState({ play: false });
        }
      }
    };

    // Create the observer.
    const observer = new IntersectionObserver(intersectionCallback, {
      threshold: 1
    });
    observer.observe(this.observerRef.current);
    this.setState({ observer });
  }

  startAnimation() {
    const { number, prefix, suffix, multiples } = this.props;

    const options = {
      easingFn,
      startVal: 1,
      duration: 2,
      prefix: prefix ?? "",
      suffix: suffix ?? ""
    };

    this.setState({ play: true });
    if (multiples) {
      const size = number.length;
      number.map((item, key) => {
        let element = this.observerRef.current.querySelector(
          `span:nth-child(${key + 1})`
        );
        options.suffix = key + 1 < size ? suffix : "";
        let animation = new CountUp(element, item, options);
        if (!animation.error) {
          animation.start();
        }
        return item;
      });
    } else {
      const animation = new CountUp(this.observerRef.current, number, options);
      if (!animation.error) {
        animation.start();
      }
    }
  }

  render() {
    const {
      description,
      number,
      multiples,
      prefix,
      suffix,
      align,
      theme
    } = this.props;

    return (
      <>
        <Numbers active={this.state.play} ref={this.observerRef} as="h4">
          {multiples ? "" : prefix ?? ""}
          {multiples
            ? number.map((item, key) => {
                let part = key + 1 < number.length ? suffix : "";
                return (
                  <span key={key}>
                    {item}
                    {part}
                  </span>
                );
              })
            : number}
          {multiples ? "" : suffix ?? ""}
        </Numbers>
        <Div active={this.state.play} align={align} theme={theme}>
          <Body20
            theme={{ color: "inherit", margins: "0" }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Div>
      </>
    );
  }
}

export default MetricSnippetAlt;
