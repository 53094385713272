import React from "react";
import styled from "styled-components";
import Container from "../styles/Container";
import OutlinedText from "../styles/OutlinedText";
import { Headline80 } from "../styles/Headings";
import { Body24 } from "../styles/BodyTypes";
import { black1, white } from "../styles/Colors";
import { mediaMedium } from "../styles/variables";
import MetricSnippetAlt from "../MetricSnippetAlt";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";

const Wrapper = styled(BackgroundImage)`
  background-color: ${black1};
  padding: 120px 0;

  &:after,
  &:before {
    background-position: bottom center !important;
    background-repeat: no-repeat !important;
    background-size: 150% 20% !important;
  }

  @media (${mediaMedium}) {
    background-size: contain;
    padding: 144px 0 147px;

    &:after,
    &:before {
      background-size: contain !important;
    }
  }
`;

const Top = styled.div`
  max-width: 460px;
`;

const Grid = styled.div`
  @media (${mediaMedium}) {
    display: grid;
    grid-template-columns: repeat(2, calc(100% * 4 / 12 - 50px));
    grid-column-gap: 120px;
    grid-row-gap: 116px;
  }
`;

const Content = styled(Body24)`
  margin: 48px 0;
  color: ${white};

  @media (${mediaMedium}) {
    margin: 72px 0;
  }
`;

const Row = styled.div`
  &:not(:last-child) {
    margin-bottom: 56px;
  }

  div {
    margin-top: 14px;
  }

  @media (${mediaMedium}) {
    br {
      display: none;
    }

    &:not(:last-child) {
      margin-bottom: 0;
    }

    div {
      margin-top: 22px;
    }
  }
`;

const EntrepreneurResultsSection = ({ images }) => {
  return (
    <Wrapper fluid={images.file.childImageSharp.fluid}>
      <Container>
        <Top>
          <Headline80 as={"span"} theme={{ color: white }}>
            the{" "}
            <OutlinedText
              component={Headline80}
              fill="transparent"
              stroke={white}
              as={"span"}
            >
              Results
            </OutlinedText>
          </Headline80>
          <Content>
            With StackCommerce, EMI was able to build a commerce business from
            scratch with no risk or upfront cost. This has allowed their
            internal team to focus on building alternative revenue streams,
            while benefiting from the below wins:
          </Content>
        </Top>

        <Grid>
          <Row>
            <MetricSnippetAlt
              sufix="M"
              prefix="$"
              number="3.2"
              description="Commerce Gross Revenue <br/> Generated in First Year"
            />
          </Row>
          <Row>
            <MetricSnippetAlt
              sufix="%"
              number="34"
              description="Clickthrough Rate: <br/> From Article to Store"
            />
          </Row>
          <Row>
            <MetricSnippetAlt
              number={[10, 14]}
              suffix="-"
              multiples={true}
              description="Articles Published Each Week"
            />
          </Row>
          <Row>
            <MetricSnippetAlt
              sufix="K+"
              number="14"
              description="Articles Published Each Week"
            />
          </Row>
        </Grid>
      </Container>
    </Wrapper>
  );
};

const EntrepreneurResults = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "35-results-decoration.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <EntrepreneurResultsSection images={data} />;
};

export default EntrepreneurResults;
