import React from "react";
import styled from "styled-components";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import HomeLanding from "../../components/publishers/EntrepreneurLanding";
import HeroBlueCTA from "../../components/HeroBlueCTA";
import EntrepreneurChallenge from "../../components/publishers/EntrepreneurChallenge";
import HeroWhite from "../../components/HeroWhite";
import { Headline80 } from "../../components/styles/Headings";
import { Body24 } from "../../components/styles/BodyTypes";
import OutlinedText from "../../components/styles/OutlinedText";
import ContentSnippet from "../../components/ContentSnippet";
import EntrepreneurResults from "../../components/publishers/EntrepreneurResults";
import ShowCaseEntrepreneur from "../../components/publishers/ShowCaseEntrepreneur";
import EntrepreneurSlider from "../../components/publishers/EntrepreneurSlider";
import { Link } from "gatsby";
import { white } from '../../components/styles/Colors'

const Overflow = styled.div`
  overflow: hidden;
`;

const EntrepreneurPage = () => (
  <Layout>
    <Seo
      title="Entrepreneur Case Study"
      description="How StackCommerce helps Entrepreneur Media Inc. monetize with commerce content, a branded shop, and exclusive merchandise."
    />

    <Overflow>
      <HomeLanding link={{ label: "Sign Up" }} section="CASE STUDY">
        ENTREPRENEUR x STACK
      </HomeLanding>

      <EntrepreneurChallenge title1="The" title2="Challenge">
        <Body24 theme={{ color: white, margins: "0 0 4.2rem" }}>
          Since 2018, Stack has worked closely with Entrepreneur Media Inc.
          to establish and grow its commerce business from the ground up. At
          the time the partnership was initiated, Entrepreneur primarily
          relied upon traditional media to meet its revenue goals and looked
          to add a commerce business without devoting significant internal
          resources.
        </Body24>
        <Body24 theme={{ color: white, margins: "4.2rem 0 0" }}>
          Before working with StackCommerce, EMI was understandably hesitant
          about the high overhead costs of commerce, e.g. sales,
          merchandising, customer service, and operations. They were
          searching for a solution that allowed them to be less ad dependant
          while offering a curated, native commerce experience that
          resonated with its readers.
        </Body24>
      </EntrepreneurChallenge>
    </Overflow>

    <EntrepreneurSlider />

    <HeroWhite>
      <div>
        <Headline80>
          THE <br />
          <OutlinedText component={Headline80} as={"span"}>
            SOLUTION
          </OutlinedText>
        </Headline80>
      </div>
      <div>
        <Body24 theme={{ margins: "0" }}>
          Rather than utilizing internal resources to test the long-term revenue
          potential of eCommerce, EMI integrated with StackCommerce’s robust
          platform and solution to launch the Entrepreneur Store.
        </Body24>
        <Body24 theme={{ margins: "68px 0 0" }}>
          With the realized success, EMI has been able to shift its central
          business to a blend of content consumption and content marketing.
        </Body24>
      </div>
    </HeroWhite>

    <ShowCaseEntrepreneur />

    <HeroWhite title="Stack’s solution enabled EMI to:" small={true}>
      <div>
        <ContentSnippet
          top="01"
          title="Optimize product selection"
          small={true}
        >
          A wide assortment of product categories allowed EMI to test product
          types and price points and collect valuable reader data that informs
          future curation and overall business objectives.
        </ContentSnippet>
      </div>
      <div>
        <ContentSnippet
          top="02"
          title="Offer readers bespoke content"
          small={true}
        >
          Custom commerce content is provided by StackCommerce’s Content Studio
          so to not require additional resources internally. Commerce content
          integrates with editorial to augment the overall reader experience.
        </ContentSnippet>
      </div>
      <div>
        <ContentSnippet top="03" title="Target new readers" small={true}>
          Agency-style paid marketing solutions target potential readers with
          commerce content to grow revenue and readership simultaneously.
        </ContentSnippet>
      </div>
      <div>
        <ContentSnippet
          top="04"
          title="Build lifetime customer relationships"
          small={true}
        >
          Email capture widgets and lifecycle marketing campaigns engage and
          foster customer relationships beyond the first purchase.
        </ContentSnippet>
      </div>
    </HeroWhite>

    <EntrepreneurResults />

    <HeroBlueCTA
      title="LET’S BUILD"
      outline="YOU A SHOP"
      description="It’s easy—just tell us a little bit about your site and audience and you’ll be on your way."
      link={{ to: "/contact/", state: { from: "publisher" }, as: Link }}
    />
  </Layout>
);

export default EntrepreneurPage;
