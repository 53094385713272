import React from "react";
import styled from "styled-components";
import Container from "./styles/Container";
import { white } from "./styles/Colors";
import { mediaMedium } from "./styles/variables";
import { Headline64 } from "./styles/Headings";

const Wrapper = styled.div`
  background: ${white};
  padding: 88px 0;

  @media (${mediaMedium}) {
    padding: 144px 0 151px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-row-gap: ${(props) => (props.small ? "24px" : "54px")};
  grid-template-columns: 100%;

  p {
    margin-bottom: 0;
  }

  @media (${mediaMedium}) {
    ${(props) => (props.small ? "justify-content: space-between;" : "")};
    grid-template-columns: ${(props) =>
      props.small ? "repeat(2, calc(100% * 5 / 12))" : "50% 48%"};
    grid-column-gap: 24px;
    grid-row-gap: 74px;
  }
`;

const TitleRow = styled.div`
  max-width: 870px;
  margin-bottom: 80px;

  @media (${mediaMedium}) {
    margin-bottom: 88px;
  }
`;

const HeroWhite = ({ title, children, small }) => {
  return (
    <Wrapper>
      <Container>
        {title && (
          <TitleRow>
            <Headline64>{title}</Headline64>
          </TitleRow>
        )}
        <Grid small={small}>{children}</Grid>
      </Container>
    </Wrapper>
  );
};

export default HeroWhite;
